<template>
  <section id="vision" class="mt-5">
    <div class="container">
        <h5 class="site_title fw-bold text-center"> {{  $t('home.vision')  }} </h5>
        <p class="vision_desc text-center w-75 mx-auto mt-4">
          {{ vision }}
        </p>
    </div>
  </section>
</template>

<script>
import { mapGetters , mapActions } from 'vuex';
export default {
  computed:{
        ...mapGetters('setting', ['vision'])
    },
    methods:{
        ...mapActions('setting', ['getVisions'])
    },
    mounted(){
        this.getVisions();
    }
}
</script>

<style>
    .vision_desc{
        font-size: 16px;
        font-weight: 600;
    }
</style>