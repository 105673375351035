export default {
  "nav": {
    "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "exploreJobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore Jobs"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "whoUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])},
    "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms and Conditions"])},
    "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alerts"])}
  },
  "home": {
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for a Job"])},
    "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore Jobs"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download the App Now"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Sevi Broadcast"])},
    "part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partners of Success"])},
    "vision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Vision"])},
    "about_footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Sevi"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
    "links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Useful Links"])},
    "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join Us"])},
    "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Orders"])},
    "follow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow Us"])},
    "rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All rights reserved for Sevi website"])},
    "awamer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Designed and programmed by Awamer Network Establishment"])}
  },
  "job": {
    "cond": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Conditions"])},
    "qual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualifications"])},
    "similar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Similar Jobs"])}
  },
  "auth": {
    "specialSpec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Specialzation"])},
    "specialPlcSpec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["please select job specialization"])},
    "phoneRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone field is required"])},
    "phoneValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number must be more than 9 digits"])},
    "newPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the new mobile number"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the following information to login to your account"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
    "phonePlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your phone number"])},
    "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "passPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your password"])},
    "passPlc1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your new password"])},
    "fotgetPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot Password"])},
    "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Having trouble registering?"])},
    "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please contact us"])},
    "newAcc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you have an account?"])},
    "reg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Account"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
    "userPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your username"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
    "messagePlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please write your message here"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "fotgetPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your mobile number to receive your verification code"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify"])},
    "resetPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Password"])},
    "confirmPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password"])},
    "confirmPass1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm New Password"])},
    "confirmPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please confirm your password"])},
    "confirmPlc1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please confirm your new password"])},
    "iden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password Matched"])},
    "notIden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password does not match"])},
    "set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set"])},
    "otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification Code"])},
    "otpPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the verification code sent to your mobile number to activate your account"])},
    "haveNot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Didn't receive the code?"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend"])},
    "remain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time Remaining"])},
    "second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Second"])},
    "regPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the following information to create a new account so you can search for new jobs that suit you in no time"])},
    "keep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete Information"])},
    "haveAcc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you have an account?"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "emailPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your email"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
    "genderPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select your gender"])},
    "birth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of Birth"])},
    "birthPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your date of birth"])},
    "nat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationality"])},
    "natPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your nationality"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "cityPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select your city"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
    "countryPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select your country"])},
    "noCity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No City"])},
    "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place of Residence"])},
    "placePlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your place of residence"])},
    "cv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curriculum Vitae"])},
    "qual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Degree"])},
    "qualPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select your degree"])},
    "field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General specialization"])},
    "fieldPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select your general specialization"])},
    "special": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specialization"])},
    "specialPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select your specialization"])},
    "cer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional Certificates"])},
    "cerPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select your professional certificates"])},
    "skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skills"])},
    "skillsPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select your skills"])},
    "jobName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Title"])},
    "ifExist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If exists"])},
    "namePlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the job title"])},
    "yearsExper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Years of Experience"])},
    "yearPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select years of experience"])},
    "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Company"])},
    "currentPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the current company"])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skip"])},
    "oldPassPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the old password"])},
    "oldPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Password"])},
    "pass1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "qualTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualifications"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Information"])},
    "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video"])},
    "videoE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Introductory Video"])},
    "changePhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Mobile Number"])},
    "changePlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your password to change your mobile number"])}
  },
  "chat": {
    "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chats"])},
    "single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Single Chat"])}
  },
  "common": {
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Changes"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changes saved successfully"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
    "avilable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available Jobs"])},
    "jobType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Type"])},
    "showDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Details"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
    "contactPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the following information to contact us and identify your problem"])},
    "jobField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Field"])},
    "fieldPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the job field"])},
    "jobTyp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Type"])},
    "typePlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the job type"])},
    "edQual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Educational Qualification"])},
    "qualPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the educational qualification"])},
    "exper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experience"])},
    "experPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the experience"])},
    "skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skills"])},
    "skillPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the skill"])},
    "cer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional Certificates"])},
    "cerPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the professional certificates"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "avialble": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available Job"])},
    "jobDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Details"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publication Date"])},
    "salary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salary"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
    "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders"])},
    "applied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applied"])},
    "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepted"])},
    "refused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejected"])},
    "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finished"])},
    "avilabe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available Job"])},
    "sendMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send us your problem and we will contact you soon"])},
    "showAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show All"])},
    "noJobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No jobs yet"])},
    "noSimilar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No similar jobs"])},
    "applay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply for the job"])},
    "res": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasks and Responsibilities"])},
    "conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Conditions"])},
    "signOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Out"])},
    "appliedDone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your job application has been submitted, please wait"])},
    "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your job application has been rejected"])},
    "acceptedDone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your job application has been accepted"])},
    "noOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Orders Yet"])}
  },
  "setting": {
    
  }
}