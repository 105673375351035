<template>
  <section id="compRegister" class="mt-4">
    <div class="container">
      <!-- breadcrumb  -->
      <div class="breadcrumb d-flex">
        <router-link to="/" class="inActive"> {{ $t("nav.main") }} </router-link
        >&nbsp; - &nbsp;
        <p class="active mainColor">{{ $t("auth.reg") }}</p>
      </div>

      <section class="loginSection" style="width: 93%; margin: auto">
        <h3 class="fw-bold mb-3">{{ $t("auth.reg") }}</h3>
        <p class="registerParagraph fw-bold d-flex align-items-center">
          <span class="step flex_center">2</span>
          {{ $t("auth.regPlc") }}
        </p>

        <form
          class="flex flex-wrap gap-3 p-fluid mt-4"
          @submit.prevent="setProfile"
          ref="register"
        >
          <div class="row">
            <!-- email  -->
            <div class="col-md-6 mb-3">
              <div class="position-relative flex-auto">
                <label for="integeronly" class="label fw-bold block mb-2">
                  {{ $t("auth.email") }}
                </label>
                <InputText
                  type="email"
                  class="defaultInput2"
                  name="email"
                  required
                  v-model="name"
                  :placeholder="$t('auth.emailPlc')"
                />
                <!-- icon  -->
                <div class="inputIcon">
                  <img :src="require('@/assets/imgs/sms.svg')" alt="" />
                </div>
              </div>
            </div>

            <!-- gender  -->
            <div class="col-md-6 mb-3">
              <div class="position-relative flex-auto">
                <label for="integeronly" class="label fw-bold block mb-2">
                  {{ $t("auth.gender") }}
                </label>
                <Dropdown
                  v-model="gender"
                  :options="genders"
                  optionLabel="title"
                  class="w-full md:w-14rem w-100 position-relative"
                  :placeholder="$t('auth.genderPlc')"
                />
                <!-- icon  -->
                <div class="inputIcon">
                  <img :src="require('@/assets/imgs/sms.svg')" alt="" />
                </div>
              </div>
            </div>

            <!-- birthday  -->
            <div class="col-md-6 mb-3">
              <div class="position-relative flex-auto">
                <label for="integeronly" class="label fw-bold block mb-2">
                  {{ $t("auth.birth") }}
                </label>
                <Calendar
                  v-model="date"
                  class="defaultInput"
                  :placeholder="$t('auth.birthPlc')"
                />

                <!-- icon  -->
                <div class="inputIcon">
                  <img :src="require('@/assets/imgs/calendar-2.svg')" alt="" />
                </div>
              </div>
            </div>

            <!-- nationality  -->
            <div class="col-md-6 mb-3">
              <div class="position-relative flex-auto">
                <label for="integeronly" class="label fw-bold block mb-2">
                  {{ $t("auth.nat") }}
                </label>
                <Dropdown
                  v-model="nation"
                  :options="nationalities"
                  filter
                  optionLabel="title"
                  class="w-full md:w-14rem w-100 position-relative"
                  :placeholder="$t('auth.natPlc')"
                />
                <!-- icon  -->
                <div class="inputIcon">
                  <img :src="require('@/assets/imgs/card-pos.svg')" alt="" />
                </div>
              </div>
            </div>

            <!-- country  -->
            <div class="col-md-6 mb-3">
              <div class="position-relative flex-auto">
                <label for="integeronly" class="label fw-bold block mb-2">
                  {{ $t("auth.country") }}
                </label>
                <InputText
                  type="text"
                  class="defaultInput2"
                  name="country"
                  required
                  v-model="selectedCountry"
                  :placeholder="$t('auth.countryPlc')"
                />
                <!-- icon  -->
                <div class="inputIcon">
                  <img :src="require('@/assets/imgs/calendar-2.svg')" alt="" />
                </div>
              </div>
            </div>

            <!-- city  -->
            <div class="col-md-6 mb-3">
              <div class="position-relative flex-auto">
                <label for="integeronly" class="label fw-bold block mb-2">
                  {{ $t("auth.city") }}
                </label>
                <InputText
                  type="text"
                  class="defaultInput2"
                  name="city"
                  required
                  v-model="selectedCity"
                  :placeholder="$t('auth.cityPlc')"
                />
                <!-- icon  -->
                <div class="inputIcon">
                  <img :src="require('@/assets/imgs/calendar-2.svg')" alt="" />
                </div>
              </div>
            </div>

            <div class="row" style="border: 1px solid #ddd; padding: 12px; margin-bottom: 20px">
              <!-- qualifications  -->
              <div class="col-md-6 mb-3">
                <div class="position-relative flex-auto">
                  <label for="integeronly" class="label fw-bold block mb-2">
                    {{ $t("auth.qual") }}
                  </label>
                  <Dropdown
                    v-model="qualification"
                    filter
                    :options="qualifications"
                    @change="getval1"
                    optionLabel="title"
                    class="w-full md:w-14rem w-100 position-relative"
                    :placeholder="$t('auth.qualPlc')"
                  />
                  <!-- icon  -->
                  <div class="inputIcon">
                    <img :src="require('@/assets/imgs/book.svg')" alt="" />
                  </div>
                </div>
              </div>

              <!-- Specializations  -->
              <div class="col-md-6 mb-3">
                <div class="position-relative flex-auto">
                  <label for="integeronly" class="label fw-bold block mb-2">
                    {{ $t("auth.field") }}
                  </label>
                  <Dropdown
                    v-model="selectedSpecializations"
                    @change="getval2"
                    filter
                    :options="specializations"
                    optionLabel="title"
                    class="w-full md:w-14rem w-100 position-relative"
                    :placeholder="$t('auth.fieldPlc')"
                  />
                  <!-- icon  -->
                  <div class="inputIcon">
                    <img
                      :src="require('@/assets/imgs/document-text.svg')"
                      alt=""
                    />
                  </div>
                </div>
                <div class="mt-2 mb-2 fs-13">
                  اذ لم تجد تخصصك العام
                  <span
                    class="text-danger"
                    @click="addSpecialization = true"
                    style="cursor: pointer"
                    >اضغط هنا</span
                  >
                </div>
                <!-- add cer  Dialog -->
                <Dialog
                  v-model:visible="addSpecialization"
                  modal
                  :style="{ width: '50vw' }"
                >
                  <form
                    ref="addSpecializations"
                    @submit.prevent="addNewSpecialization"
                    class="flex flex-wrap gap-3 p-fluid"
                  >
                    <h5 class="fw-bold text-center">اضافة تخصص عام</h5>

                    <div class="form-group mb-3">
                      <label for=""> اسم التخصص العام بالعربية </label>
                      <input
                        type="text"
                        class="form-control"
                        name="title[ar]"
                        v-model="specialization_name_ar"
                        placeholder=" الرجاء ادخال اسم التخصص بالعربية"
                      />
                    </div>
                    <div class="form-group">
                      <label for=""> اسم التخصص العام بالانجليزية </label>
                      <input
                        type="text"
                        class="form-control"
                        name="title[en]"
                        v-model="specialization_name_en"
                        placeholder="الرجاء ادخال اسم التخصص بالانجليزية"
                      />
                    </div>

                    <!-- submit  -->
                    <div class="mt-4">
                      <button
                        class="main_btn fs-5 w-75 mx-auto flex_center"
                        :disabled="specializations_disabled"
                      >
                        <span v-if="!specializations_disabled"> تأكيد </span>
                        <div
                          class="spinner-border"
                          role="status"
                          v-if="specializations_disabled"
                        >
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      </button>
                    </div>
                  </form>
                </Dialog>
              </div>

              <!-- SpecificSpecializations  -->
              <div class="col-md-6 mb-3">
                <div class="position-relative flex-auto">
                  <label for="integeronly" class="label fw-bold block mb-2">
                    {{ $t("auth.special") }}
                  </label>
                  <Dropdown
                    v-model="selectedSpecificSpecializations"
                    @change="getval3"
                    filter
                    :options="specificSpecializations"
                    optionLabel="title"
                    class="w-full md:w-14rem w-100 position-relative"
                    :placeholder="$t('auth.specialPlc')"
                  />
                  <!-- icon  -->
                  <div class="inputIcon">
                    <img
                      :src="require('@/assets/imgs/document-text.svg')"
                      alt=""
                    />
                  </div>
                </div>
                <div class="mt-2 mb-2 fs-13">
                  اذ لم تجد تخصصك الدقيق
                  <span
                    class="text-danger"
                    @click="addSpecificSpecializations = true"
                    style="cursor: pointer"
                    >اضغط هنا</span
                  >
                </div>
                <!-- add cer  Dialog -->
                <Dialog
                  v-model:visible="addSpecificSpecializations"
                  modal
                  :style="{ width: '50vw' }"
                >
                  <form
                    ref="addSpecifics"
                    @submit.prevent="addNewSpecificSpecialization"
                    class="flex flex-wrap gap-3 p-fluid"
                  >
                    <h5 class="fw-bold text-center">اضافة تخصص دقيق</h5>

                    <div class="form-group mb-3">
                      <label for=""> اسم التخصص الدقيق بالعربية </label>
                      <input
                        type="text"
                        class="form-control"
                        name="title[ar]"
                        v-model="specificSpecializations_name_ar"
                        placeholder=" الرجاء ادخال اسم التخصص بالعربية"
                      />
                    </div>
                    <div class="form-group">
                      <label for=""> اسم التخصص الدقيق بالانجليزية </label>
                      <input
                        type="text"
                        class="form-control"
                        name="title[en]"
                        v-model="specificSpecializations_name_en"
                        placeholder="الرجاء ادخال اسم التخصص بالانجليزية"
                      />
                    </div>

                    <!-- submit  -->
                    <div class="mt-4">
                      <button
                        class="main_btn fs-5 w-75 mx-auto flex_center"
                        :disabled="specificSpecializations_disabled"
                      >
                        <span v-if="!specificSpecializations_disabled">
                          تأكيد
                        </span>
                        <div
                          class="spinner-border"
                          role="status"
                          v-if="specificSpecializations_disabled"
                        >
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      </button>
                    </div>
                  </form>
                </Dialog>
              </div>

              <div class="col-md-6 mb-3">
                <div class="position-relative flex-auto">
                  <div
                    v-for="(q, i) in mainArray"
                    :key="q"
                    class="d-flex align-items-center justify-content-between gap-2"
                    style="background-color: #f8f8f8 ; border-bottom: 1px solid #cacaca; padding: 5px;margin-bottom:10px; border-radius:5px; font-size:12px"
                  >
                    <div v-if="q.value1">
                      <p style="margin-bottom: 0px">الدرجة العلمية: {{ q.value1 }}</p>
                    </div>
                    <div v-if="q.value2">
                      <p style="margin-bottom: 0px">التخصص العام: {{ q.value2 }}</p>
                    </div>
                    <div v-if="q.value3">
                      <p style="margin-bottom: 0px">التخصص الدقيق : {{ q.value3 }}</p>
                    </div>

                    <div
                      @click="removeMain(i)"
                      style="
                        color: #fff;
                        cursor: pointer;
                        width: 20px;
                        height: 20px;
                        background-color: red;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 8px;
                        flex-shrink:0
                      "
                    >
                      <i class="fa-solid fa-x"></i>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12 w-100 mb-4">
                <button
                  class="sec_btn pt-3 pb-3 w-25 mx-3 fw-bold"
                  style="margin: auto !important ; display: block"
                  type="button"
                  :disabled="!array1.length && !array3.length && !array3.length"
                  @click="getAllItems"
                >
                  إضافة
                </button>
              </div>
            </div>
            <!-- cv  -->
            <div class="col-md-6 mb-3">
              <div class="position-relative flex-auto">
                <label for="integeronly" class="label fw-bold block mb-2">
                  {{ $t("auth.cv") }}
                </label>
                <Toast />

                <FileUpload
                  mode="basic"
                  name="cv"
                  accept=".pdf"
                  class="defaultInput2"
                  :maxFileSize="100000000000"
                  @upload="onUpload"
                />
                <!-- icon  -->
                <div class="inputIcon">
                  <img :src="require('@/assets/imgs/pdf.svg')" alt="" />
                </div>
              </div>
            </div>

            <!-- certifications  -->
            <div class="col-md-6 mb-3">
              <div class="position-relative flex-auto">
                <label for="integeronly" class="label fw-bold block mb-2">
                  {{ $t("auth.cer") }}
                </label>
                <MultiSelect
                  v-model="cer"
                  filter
                  :options="certifications"
                  optionLabel="title"
                  :placeholder="$t('auth.cerPlc')"
                  :maxSelectedLabels="5"
                  class="w-full md:w-20rem defaultInput2 position-relative"
                />
                <!-- icon  -->
                <div class="inputIcon">
                  <img
                    :src="require('@/assets/imgs/document-text.svg')"
                    alt=""
                  />
                </div>
              </div>
            </div>

            <!-- skills  -->
            <div class="col-md-6 mb-3">
              <div class="position-relative flex-auto">
                <label for="integeronly" class="label fw-bold block mb-2">
                  {{ $t("auth.skills") }}
                </label>
                <MultiSelect
                  v-model="skill"
                  filter
                  :options="skills"
                  optionLabel="title"
                  :placeholder="$t('auth.skillsPlc')"
                  :maxSelectedLabels="5"
                  class="w-full md:w-20rem defaultInput2 position-relative"
                />

                <!-- icon  -->
                <div class="inputIcon">
                  <img
                    :src="require('@/assets/imgs/document-text.svg')"
                    alt=""
                  />
                </div>
              </div>
            </div>

            <!-- job name  -->
            <div class="col-md-6 mb-3">
              <div class="position-relative flex-auto">
                <label for="integeronly" class="label fw-bold block mb-2">
                  {{ $t("auth.jobName") }}
                  <span class="ifExist mx-2">
                    {{ $t("auth.ifExist") }}
                    <i class="fa-solid fa-star-of-life"></i>
                  </span>
                </label>
                <InputText
                  type="text"
                  class="defaultInput2"
                  v-model="job_title"
                  name="job_title"
                  :placeholder="$t('auth.namePlc')"
                />
                <!-- icon  -->
                <div class="inputIcon">
                  <img
                    :src="require('@/assets/imgs/document-text.svg')"
                    alt=""
                  />
                </div>
              </div>
            </div>

            <!-- yearsExperience  -->
            <div class="col-md-6 mb-3">
              <div class="position-relative flex-auto">
                <label for="integeronly" class="label fw-bold block mb-2">
                  {{ $t("auth.yearsExper") }}
                </label>
                <Dropdown
                  v-model="experience"
                  filter
                  :options="experiences"
                  optionLabel="title"
                  class="w-full md:w-14rem w-100 position-relative"
                  :placeholder="$t('auth.yearPlc')"
                />
                <!-- icon  -->
                <div class="inputIcon">
                  <img
                    :src="require('@/assets/imgs/document-text.svg')"
                    alt=""
                  />
                </div>
              </div>
            </div>

            <!-- current company  -->
            <div class="col-md-6 mb-3">
              <div class="position-relative flex-auto">
                <label for="integeronly" class="label fw-bold block mb-2">
                  {{ $t("auth.current") }}
                  <span class="ifExist mx-2">
                    {{ $t("auth.ifExist") }}
                    <i class="fa-solid fa-star-of-life"></i>
                  </span>
                </label>
                <InputText
                  type="text"
                  class="defaultInput2"
                  v-model="company_name"
                  name="company_name"
                  :placeholder="$t('auth.currentPlc')"
                />
                <!-- icon  -->
                <div class="inputIcon">
                  <img
                    :src="require('@/assets/imgs/document-text.svg')"
                    alt=""
                  />
                </div>
              </div>
            </div>

            <!-- cv  -->
            <div class="col-md-6 mb-3">
              <div class="position-relative flex-auto">
                <label for="integeronly" class="label fw-bold block mb-2">
                  الصورة الشخصية
                </label>

                <FileUpload
                  mode="basic"
                  v-model="cv"
                  name="image"
                  accept="image/*"
                  class="defaultInput2"
                  :maxFileSize="100000000000"
                  @upload="onUpload"
                />
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-center">
            <button class="main_btn pt-3 pb-3 w-25" :disabled="disabled">
              <span v-if="!disabled">{{ $t("auth.keep") }} </span>
              <div class="spinner-border mx-2" role="status" v-if="disabled">
                <span class="visually-hidden">Loading...</span>
              </div>
            </button>
            <button
              class="sec_btn pt-3 pb-3 w-25 mx-3 fw-bold"
              type="button"
              @click="skip"
            >
              {{ $t("auth.skip") }}
            </button>
          </div>
        </form>
      </section>
    </div>
    <Toast />
    <payment :openPayment="openPayment" />
  </section>
</template>

<script>
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import Calendar from "primevue/calendar";
import FileUpload from "primevue/fileupload";
import MultiSelect from "primevue/multiselect";
import Toast from "primevue/toast";
import Dialog from "primevue/dialog";
import axios from "axios";

// payment modal
import payment from "./paymentDialog.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      email: "",
      genders: [
        {
          id: 1,
          name: "male",
          title: "ذكر",
        },
        {
          id: 2,
          name: "female",
          title: "انثى",
        },
      ],
      gender: null,
      date: null,
      nation: null,
      selectedCity: null,
      selectedCountry: null,
      locations: {
        lat: 0,
        lng: 0,
      },
      currentLocation: {},
      address: "",
      googleMap: false,
      qualifications: [],
      qualification: null,
      emp: null,
      specialize: null,
      cer: [],
      skill: [],

      specializations: [],
      selectedSpecializations: null,
      specializations_disabled: false,
      addSpecialization: false,
      specialization_name_ar: "",
      specialization_name_en: "",

      specificSpecializations: [],
      selectedSpecificSpecializations: null,
      specificSpecializations_disabled: false,
      addSpecificSpecializations: false,
      specificSpecializations_name_ar: "",
      specificSpecializations_name_en: "",

      job_title: null,
      company_name: null,
      experience: null,
      disabled: false,

      openPayment: false,
      cv: null,
      array1: [],
      array2: [],
      array3: [],
      mainArray: [],
      lastObject: false,
      first: "",
      second: "",
    };
  },
  computed: {
    ...mapGetters("common", [
      "nationalities",
      "cities",
      "emps",
      "specs",
      "certifications",
      "skills",
      "experiences",
    ]),
  },
  components: {
    InputText,
    Dropdown,
    Calendar,
    FileUpload,
    MultiSelect,
    Toast,
    payment,
    Dialog,
  },
  methods: {
    removeMain(i) {
      this.mainArray.splice(i, 1);
    },
    getAllItems() {
      this.lastObject = {};
      this.lastObject = {
        id1: this.array1[0] ? this.array1[0].id1 : "",
        value1: this.array1[0] ? this.array1[0].value1 : "",
        id2: this.array2[0] ? this.array2[0].id2 : "",
        value2: this.array2[0] ? this.array2[0].value2 : "",
        id3: this.array3[0] ? this.array3[0].id3 : "",
        value3: this.array3[0] ? this.array3[0].value3 : "",
      };

      this.mainArray.some((e) => {
        JSON.stringify(e) == JSON.stringify(this.lastObject);
        this.first = JSON.stringify(e);
        this.second = JSON.stringify(this.lastObject);
        // console.log();
      });
      if (this.first == this.second && this.mainArray.length > 0) {
        this.$toast.add({
          severity: "error",
          summary: "تم اضافتها من قبل",
          life: 3000,
        });
      } else {
        this.mainArray.push({
          id1: this.array1[0] ? this.array1[0].id1 : "",
          value1: this.array1[0] ? this.array1[0].value1 : "",
          id2: this.array2[0] ? this.array2[0].id2 : "",
          value2: this.array2[0] ? this.array2[0].value2 : "",
          id3: this.array3[0] ? this.array3[0].id3 : "",
          value3: this.array3[0] ? this.array3[0].value3 : "",
        });
      }

      this.selectedSpecializations = [];
      this.selectedSpecificSpecializations = [];
      this.qualification = [];
      this.array1 = [];
      this.array2 = [];
      this.array3 = [];
    },
    getval1() {
      this.array1 = [];
      if (this.qualification) {
        this.array1.push({
          id1: this.qualification.id,
          value1: this.qualification.title,
        });
      }
    },
    getval2() {
      this.getSpecificSpecializations()
      this.array2 = [];
      if (this.selectedSpecializations) {
        this.array2.push({
          id2: this.selectedSpecializations.id,
          value2: this.selectedSpecializations.title,
        });
      }
      console.log(this.array2);
    },
    getval3() {
      this.array3 = [];
      if (this.selectedSpecificSpecializations) {
        this.array3.push({
          id3: this.selectedSpecificSpecializations.id,
          value3: this.selectedSpecificSpecializations.title,
        });
      }

      // console.log(this.array3);
    },
    ...mapActions("common", [
      "getNations",
      "getCities",
      "getEmployment",
      "getSpecilizations",
      "getCertifications",
      "getSkills",
      "getExperiences",
    ]),
    // get current location
    geolocation() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.locations = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      });
    },
    // get Specializations
    async getSpecializations() {
      await axios
        .get("specializations")
        .then((res) => {
          if (res.data.key == "success") {
            this.specializations = res.data.data;
          } else {
            this.$toast.add({
              severity: "error",
              summary: res.data.msg,
              life: 3000,
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // get qualifications
    async getQualifications() {
      await axios
        .get("qualifications")
        .then((res) => {
          if (res.data.key == "success") {
            this.qualifications = res.data.data;
          } else {
            this.$toast.add({
              severity: "error",
              summary: res.data.msg,
              life: 3000,
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },

    // get SpecificSpecializations
    // async getSpecificSpecializations() {
    //   await axios
    //     .get("specific-specializations")
    //     .then((res) => {
    //       if (res.data.key == "success") {
    //         this.specificSpecializations = res.data.data;
    //       } else {
    //         this.$toast.add({
    //           severity: "error",
    //           summary: res.data.msg,
    //           life: 3000,
    //         });
    //       }
    //     })
    //     .catch((err) => {
    //       console.error(err);
    //     });
    // },
    async getSpecificSpecializations() {
      const fd = new FormData();
      fd.append("specialization_id",this.selectedSpecializations.id)
      await axios
        .post("get-specific-specializations-by-parent",fd)
        .then((res) => {
          if (res.data.key == "success") {
            this.specificSpecializations = res.data.data;
          } else {
            this.$toast.add({
              severity: "error",
              summary: res.data.msg,
              life: 3000,
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // add skill
    async addNewSpecialization() {
      this.specializations_disabled = true;
      const fd = new FormData(this.$refs.addSpecializations);

      await axios
        .post("company/add-specialization", fd, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          if (res.data.key === "success") {
            this.$toast.add({
              severity: "success",
              summary: res.data.msg,
              life: 3000,
            });
            this.addSpecialization = false;
            setTimeout(() => {
              this.getSpecializations();
            }, 1000);
          } else {
            this.$toast.add({
              severity: "error",
              summary: res.data.msg,
              life: 3000,
            });
          }
          this.specializations_disabled = false;
        });
    },

    async addNewSpecificSpecialization() {
      this.specificSpecializations_disabled = true;
      const fd = new FormData(this.$refs.addSpecifics);
      if (this.selectedSpecializations) {
        fd.append("specialization_id", this.selectedSpecializations.id);
      }

      await axios
        .post("company/add-specific-specialization", fd, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          if (res.data.key === "success") {
            this.$toast.add({
              severity: "success",
              summary: res.data.msg,
              life: 3000,
            });
            this.addSpecificSpecializations = false;
            setTimeout(() => {
              this.getSpecificSpecializations();
            }, 1000);
          } else {
            this.$toast.add({
              severity: "error",
              summary: res.data.msg,
              life: 3000,
            });
          }
          this.specificSpecializations_disabled = false;
        });
    },
    // get new value after dragged
    // onMarkerDragEnd(event) {
    //     // get new lat lang value
    //     const newLat = event.latLng.lat()
    //     const newLng = event.latLng.lng()
    //     this.locations = {
    //         lat: newLat,
    //         lng: newLng
    //     }

    //     // get format_address
    //     const latLng = event.latLng
    //     const geocoder = new window.google.maps.Geocoder()
    //     geocoder.geocode({ location: latLng }, (results, status) => {
    //         if (status === 'OK') {
    //             this.address = results[0].formatted_address
    //         } else {
    //             console.error('Geocode was not successful for the following reason: ' + status)
    //         }
    //     })

    // },

    onPlaceChanged(place) {
      if (place.geometry) {
        this.locations = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };
        this.address = place.formatted_address;
      }
    },
    onUpload(e) {
      this.$toast.add({
        severity: "info",
        summary: "Success",
        detail: "File Uploaded",
        life: 3000,
      });
      console.log(e);
    },

    // main function
    async setProfile() {
      this.disabled = true;
      const fd = new FormData(this.$refs.register);
      // let myArray = [];
      // common share function
      // function appendedIfSelected( formData, key , selectedValue ){
      //   if( selectedValue ){
      //     formData.append(key , selectedValue.id)
      //   }
      // }
      // append id
      // appendedIfSelected(fd, 'employment_id', this.emp);
      // fd.append(fd, "qualifications[0][specialization_id]", this.specialize.id);
      // fd.append(fd, "qualification_id", this.qualification);
      fd.append("experience_id", this.experience.id);
      // fd.append(fd, "city", this.selectedCity);
      // fd.append(fd, "country", this.selectedCountry);

      for (let i = 0; i < this.mainArray.length; i++) {
        fd.append(
          `qualifications[${i}][qualification_id]`,
          this.mainArray[i].id1
        );
        fd.append(
          `qualifications[${i}][specialization_id]`,
          this.mainArray[i].id2
        );
        fd.append(
          `qualifications[${i}][specific_specialization_id]`,
          this.mainArray[i].id3
        );
      }

      // append locations
      // if( this.address ){
      //   fd.append('map_desc', this.address)
      // }
      if (this.locations) {
        fd.append("lat", this.locations.lat);
        fd.append("lng", this.locations.lng);
      }
      // append skills
      // for (let i = 0; i < this.skill.length; i++) {
      //   appendedIfSelected(fd, `skills[${i}]`, this.skill[i]);
      // }
      // append certifications
      // for (let i = 0; i < this.cer.length; i++) {
      //   appendedIfSelected(fd, `certifications[${i}]`, this.cer[i]);
      // }
      // append gender
      if (this.gender) {
        fd.append("gender", this.gender.name);
      }
      // append birth date
      if (this.date) {
        const date = new Date(this.date);
        // Extract day, month, and year from the Date object
        const day = date.getDate();
        const month = date.getMonth() + 1; // Months are zero-based, so add 1
        const year = date.getFullYear();
        // Format the date components as a string in the desired format
        const formattedDate = `${day}-${month}-${year}`;

        fd.append("birth_date", formattedDate);
      }
      // append nation
      if (this.nation) {
        fd.append("nationality", this.nation.title);
      }

      // try catch
      try {
        const res = await this.$store.dispatch("auth/completeProfile", fd);
        if (res.success == true) {
          this.$toast.add({
            severity: "success",
            summary: res.message,
            life: 3000,
          });
          this.disabled = false;

          setTimeout(() => {
            // open payment dialog
            if (this.openPayment == true || this.openPayment == false) {
              this.openPayment = !this.openPayment;
            }
          }, 3000);
        } else {
          this.$toast.add({
            severity: "error",
            summary: res.message,
            life: 3000,
          });
          this.disabled = false;
        }
      } catch (err) {
        console.error(err);
      }
    },
    // skip
    skip() {
      this.$router.push("/");
    },
  },
  mounted() {
    this.geolocation();
    let filePlaceholder = document.querySelector(".p-button-label");
    this.getNations();
    this.getCities();
    this.getEmployment();
    this.getSpecilizations();
    this.getCertifications();
    this.getSkills();
    this.getExperiences();
    this.getQualifications();
    this.getSpecializations();
    // this.getSpecificSpecializations();
    filePlaceholder.innerHTML = "الرجاء اختيار السيرة الذاتية";
  },
  created() {},
};
</script>

<style>
.p-dropdown {
  top: 0 !important;
  padding-left: 12px;
  padding-right: 24px;
}
</style>
<style lang="scss">
.sec_btn {
  background-color: #cacaca;
  color: #333;
  padding: 7px 19px;
  border-radius: 5px;
  border: none;
}
.ifExist {
  color: #ce3932;
  font-weight: normal !important;
  svg {
    font-size: 9px !important;
    transform: translateY(-5px);
  }
}
.p-multiselect .p-multiselect-trigger {
  position: absolute;
  top: 17px;
  left: 0;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
  margin-left: 0.5rem !important;
  margin-right: 0 !important;
}
.p-fluid .p-button {
  width: 100% !important;
  border: 1px solid #ccc;
  color: #6c757d;
}
.p-button .p-button-icon-left {
  margin-left: 0.5rem;
  margin-right: 0;
  position: absolute;
  left: 10px;
}
.p-button-label {
  text-align: right;
  font-weight: normal !important;
}
.p-button-label:hover,
.p-button .p-button-icon-left:hover,
.p-fluid .p-button:hover .p-button-label,
.p-fluid .p-button:hover .p-button-icon-left {
  color: #6c757d !important;
}
.pac-container {
  z-index: 9999 !important;
}
.vue-map-hidden {
  position: absolute !important;
  top: 92px !important;
  right: 50% !important;
  transform: translate(50%, 50%) !important;
}
.p-dropdown-label {
  font-family: inherit !important;
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
  padding-left: 1.75rem !important;
  margin-left: -1.75rem !important;
  padding-right: 35px !important;
  margin-right: 0 !important;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-next {
  transform: rotate(180deg);
}
.inputIcon2 {
  position: absolute;
  top: 57%;
  left: 10px;
}
.vue-map {
  width: 100% !important;
  height: 72vh !important;
  position: relative;
  overflow: hidden;
}

.sec_btn {
  background-color: #cacaca;
  color: #333;
  padding: 7px 19px;
  border-radius: 5px;
  border: none;
}
.ifExist {
  color: #ce3932;
  font-weight: normal !important;
  svg {
    font-size: 9px !important;
    transform: translateY(-5px);
  }
}
.p-multiselect .p-multiselect-trigger {
  position: absolute;
  top: 17px;
  left: 0;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
  margin-left: 0.5rem !important;
  margin-right: 0 !important;
}
.p-fluid .p-button {
  width: 100% !important;
  border: 1px solid #ccc;
  color: #6c757d;
}
.p-button .p-button-icon-left {
  margin-left: 0.5rem;
  margin-right: 0;
  position: absolute;
  left: 10px;
}
.p-button-label {
  text-align: right;
  font-weight: normal !important;
}
.p-button-label:hover,
.p-button .p-button-icon-left:hover,
.p-fluid .p-button:hover .p-button-label,
.p-fluid .p-button:hover .p-button-icon-left {
  color: #6c757d !important;
}
.pac-container {
  z-index: 9999 !important;
}
.vue-map-hidden {
  position: absolute !important;
  top: 92px !important;
  right: 50% !important;
  transform: translate(50%, 50%) !important;
}
.p-dropdown-label {
  font-family: inherit !important;
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
  padding-left: 1.75rem !important;
  margin-left: -1.75rem !important;
  padding-right: 35px !important;
  margin-right: 0 !important;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-next {
  transform: rotate(180deg);
}
.inputIcon2 {
  position: absolute;
  top: 57%;
  left: 10px;
}
.vue-map {
  width: 100% !important;
  height: 72vh !important;
  position: relative;
  overflow: hidden;
}
</style>
